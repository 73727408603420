/* Fonts */
/* Great Vibes */
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Italianno&family=Tangerine:wght@700&display=swap');
/* Monserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/* Navbar Styles */
.navbar {
  width: 100%;
  box-shadow: 0px 5px 10px -10px #000000bf;
  overflow: hidden;
}
.site-navbar {
  width: 100%;
  background-color: #1d293a;
  overflow: hidden;
}
.site-title {
  font-family: 'Great Vibes', cursive;
  color: #3abdff;
  font-size: 25px;
}
.nav-link {
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: 1.5px;
  color: white !important;
}
.nav-link:hover {
  color: #3abdff !important;
}
