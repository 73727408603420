@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&family=Italianno&family=Tangerine:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
body {
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.section-title {
  padding: 50px 0px;
  text-align: center;
  color: #42bdfe;
}

/* Scrollbar Style */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
  background-color: #000015;
  border: 1px solid #1d293a;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #1d293a;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3abdff;
}

* {
  font-family: "Amaranth", sans-serif;
  color: white;
}

/* Fonts */
/* Great Vibes */
/* Monserrat */

/* Navbar Styles */
.navbar {
  width: 100%;
  box-shadow: 0px 5px 10px -10px #000000bf;
  overflow: hidden;
}
.site-navbar {
  width: 100%;
  background-color: #1d293a;
  overflow: hidden;
}
.site-title {
  font-family: 'Great Vibes', cursive;
  color: #3abdff;
  font-size: 25px;
}
.nav-link {
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: 1.5px;
  color: white !important;
}
.nav-link:hover {
  color: #3abdff !important;
}

#about {
  padding: 0px 20px 50px;
  min-height: 100vh;
  background: #000015;
  /* background: #1d293a; */
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-me-section-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.about-me-section-image-container {
  margin-bottom: 50px;
}
.about-me-section-image-section img {
  width: 300px;
  /* height: 400px; */
  border-radius: 10px;
  box-shadow: 0 16px 28px 0 rgba(8, 56, 103, 0);
  /* filter: grayscale(30%); */
  transition: 1.5s;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .about-me-section-image-section img {
    width: 300px;
    /* height: 250px; */
  }
}
.about-me-section-image-section img:hover {
  filter: grayscale(0%);
}
.about-me-description p {
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Roboto", sans-serif !important;
  color: #a2a9c3;
  letter-spacing: 1.2px;
  font-weight: 300;
  font-size: 16px;
}

.experience-section-title-row {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.experience-section p {
  margin: 0;
}
.experience-section strong {
  color: #c5c7ce;
  font-size: 18px;
}
.experience-details-link {
  padding: 2px 10px;
  color: #00addb;
  font-size: 12px;
  text-decoration: none;
  border: 1px solid #00addb;
  transition: all 0.4s ease-in-out;
}
.experience-details-link:hover {
  color: #42bdfe;
}
.experience-row {
  padding: 5px 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #233246;
}

.academic-row {
  margin-top: 8px;
}
p.program-name {
  font-size: 12px;
}
p.university-name {
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .experience-row p {
    font-size: 12px;
  }
  .experience-details-link {
    display: none;
  }
}

#contact {
  padding: 0px 0px 50px;
  min-height: 100vh;
  background: #000015;
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
}
h1 {
  margin: 0 !important;
}
.contact-form {
  padding: 50px;
  margin: auto;
  max-width: 700px;
  background: #1d293a;
  border: 1px solid #23324641;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.contact-form input,
.contact-form textarea {
  background: #00001586;
  color: white !important;
  border: none;
  border-radius: 10px;
}
.contact-form label {
  color: #a2a9c3;
}
/* .form-control:valid {
  background: #00001586 !important;
} */
input:focus,
textarea:focus {
  background: #00001586 !important;
}
.form-submit-button {
  color: white;
  /* background-color: #00addb; */
  background-color: #000015;
  color: #a2a9c3;
}
.form-submit-button:hover {
  color: white;
  background-color: #3abdff;
}
/* #successMessage {
  color: #3abdff;
} */

@media only screen and (max-width: 600px) {
  .contact-form {
    padding: 30px;
    margin: auto;
    width: 280px;
  }
}

.home-section {
  height: 100vh;
  width: 100%;
  background: radial-gradient(100% 225% at 100% 0%, #ff0000 0%, #000000 100%),
    linear-gradient(236deg, #00c2ff 0%, #000000 100%),
    linear-gradient(
      135deg,
      #cdffeb 0%,
      #cdffeb 36%,
      #009f9d 36%,
      #009f9d 60%,
      #07456f 60%,
      #07456f 67%,
      #0f0a3c 67%,
      #0f0a3c 100%
    );
  background-blend-mode: overlay, hard-light, normal;
  background-size: cover;
}
.home-container-section {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
/* Home Left Section */
.home-left-section {
  width: 50%;
}
.home-left-section {
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
}

.developer-name {
  padding-bottom: 10px;
  color: white;
  letter-spacing: 2px;
  font-size: calc(20px + 1vw);
  font-weight: 500;
}
.developer-title {
  margin-bottom: 15px;
  font-size: 20px;
  letter-spacing: 1px;
  color: #3abdff;
  font-weight: 500;
}

/* Social Links */
.social-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.social-icons {
  padding: 8px;
  font-size: 20px;
  height: 35px;
  width: 35px;
  color: white;
}
.linkedin-link {
  padding: 2px;
  background-color: #0d79b2;
  margin-right: 20px;
  border: 2px solid #00addb;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}
.linkedin-link:hover {
  transform: scale(1.2);
}
.github-link {
  padding: 2px;
  background-color: black;
  margin-right: 20px;
  border-radius: 50%;
  border: 2px solid #00addb;
  transition: all 0.4s ease-in-out;
}
.github-link:hover {
  transform: scale(1.2);
}
.behance-link {
  padding: 2px;
  background-color: #2773f8;
  margin-right: 20px;
  border-radius: 50%;
  border: 2px solid #00addb;
  transition: all 0.4s ease-in-out;
}
.behance-link:hover {
  transform: scale(1.2);
}
.mail-link {
  padding: 2px;
  background-color: #333333;
  border-radius: 50%;
  border: 2px solid #00addb;
  transition: all 0.4s ease-in-out;
}
.mail-link:hover {
  transform: scale(1.2);
}

/* Buttons */
.btn-contact {
  margin-top: 10px;
  height: 35px;
  width: 120px;
  font-size: 18px;
  color: white;
  background-color: #00addb;
  border: none;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-contact:hover {
  color: white;
  background-color: #42bdfe;
}

/* Home Right Section */
.home-right-section {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.home-right-section img {
  width: 80%;
}

@media only screen and (max-width: 700px) {
  .home-left-section {
    width: 100%;
  }
  .developer-name {
    font-size: 22px;
    letter-spacing: 0px;
  }
  .developer-title {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .social-icons {
    padding: 6px;
    font-size: 5px;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .linkedin-link,
  .github-link,
  .behance-link {
    margin-right: 10px;
    padding: 0px;
  }
  .mail-link {
    padding: 0px;
  }
  .btn-contact {
    margin-top: 5px;
    height: 30px;
    width: 100px;
    font-size: 14px;
    color: white;
    background-color: #00addb;
    border: none;
    transition: all 0.4s ease-in-out;
  }
}

@media only screen and (max-width: 700px) {
  .home-container-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .home-left-section {
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
  }
  .home-right-section {
    display: none;
  }
  .home-sections-buttons {
    margin: 0px;
    display: flex;
    justify-content: center;
  }
}

#projects {
  padding: 0px 20px 50px;
  background: #000015;
  min-height: 100vh;
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projects-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-card {
  padding: 20px;
  margin: 15px;
  width: 400px;
  height: 540px;
  background: #1d293a;
  border: 1px solid #23324641;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.project-card-img-section {
  background-position: top;
  background-size: cover;
  transition: linear 2s;
  border: 1px solid #aaaaaa50;
}
.project-card-img-section:hover {
  background-position: bottom;
}
.project-card img {
  height: 180px;
  width: 350px;
  border: 1px solid #80808027;
}
.project-title {
  color: #42bdfe;
}
.project-description {
  height: 120px;
  color: #a2a9c3;
}
.project-technology {
  min-height: 80px;
  color: #a2a9c3;
}
.project-technology strong {
  color: #b4bbd3;
}
.technology-section {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.technology-section p {
  margin: 0px 5px 8px 0px;
  padding: 0px 5px;
  color: #ffffff77;
  border: 1px solid #31373f;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

/* Project Button */
.project-button {
  margin: 0px 5px;
  height: 38px;
  width: 150px;
  color: #b4bbd3;
  background-color: #000015;
  border: none;
  transition: all 0.4s ease-in-out;
}
.project-button:hover {
  color: white;
  background-color: #42bdfe;
}
.project-button span {
  color: #b4bbd3;
}
.project-button:hover span {
  color: white;
}

#link-disabled {
  display: none;
}
@media only screen and (min-width: 650px) {
  .project-button span::before {
    content: "Source ";
  }
}
@media only screen and (max-width: 600px) {
  .project-card {
    width: 280px;
    min-height: 640px;
  }
  .project-description {
    margin-bottom: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .project-technology {
    margin-bottom: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

#skills {
  padding: 0px 0px 50px;
  min-height: 100vh;
  background: #000015;
  /* background: #1d293a; */
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skills-section {
  display: flex;
  justify-content: center;
  align-items: top;
  flex-wrap: wrap;
}
.frontend-skills,
.backend-skills,
.other-skills {
  margin: 10px 15px;
  padding: 20px;
  max-width: 400px;
  min-height: 500px;
  text-align: center;
  background: #1d293a;
  border: 1px solid #23324641;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.skills-card-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
  .frontend-skills,
  .backend-skills,
  .other-skills {
    width: 280;
  }
}

.skills-card-section .skill-card {
  padding: 10px;
  margin: 10px;
  width: 80px;
  height: 80px;
  font-size: 11px;
  text-align: center;
  background: #1d293a;
  border: 1px solid #23324641;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.skill-card div {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skill-card img {
  width: 70%;
}

#footer {
  /* background-color: #e2e3e7; */
  background: #1d293a;
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
#footer-text {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}
#name-in-footer {
  color: #3abdff;
  font-family: "Josefin Slab", serif;
  font-weight: 900;
}


.blog-page-body {
  height: 100%;
  padding: 120px 20px 30px 20px;
  background: #000015;
}
.blog-content-body {
  background: #1d293a;
  padding: 100px;
}
.blog-title {
  margin-bottom: 20px;
}
.blog-title h1 {
  color: #42c3fe;
  text-align: center;
}
.blog-content {
  padding: 0px 50px;
}
.code-block {
  background-color: #a2a9c3;
  color: black;
  display: block;
  padding: 20px;
}
.blog-content-body h5,
h6,
p,
b,
strong,
li {
  color: #a2a9c3;
}

@media screen and (max-width: 600px) {
  .blog-content-body {
    padding: 30px;
  }
  .blog-title {
    margin-bottom: 0px;
  }
  .blog-content {
    padding: 0px;
  }
}





.NotFound{
    text-align: center;
}
