#footer {
  /* background-color: #e2e3e7; */
  background: #1d293a;
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
#footer-text {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}
#name-in-footer {
  color: #3abdff;
  font-family: "Josefin Slab", serif;
  font-weight: 900;
}
