#contact {
  padding: 0px 0px 50px;
  min-height: 100vh;
  background: #000015;
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
}
h1 {
  margin: 0 !important;
}
.contact-form {
  padding: 50px;
  margin: auto;
  max-width: 700px;
  background: #1d293a;
  border: 1px solid #23324641;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.contact-form input,
.contact-form textarea {
  background: #00001586;
  color: white !important;
  border: none;
  border-radius: 10px;
}
.contact-form label {
  color: #a2a9c3;
}
/* .form-control:valid {
  background: #00001586 !important;
} */
input:focus,
textarea:focus {
  background: #00001586 !important;
}
.form-submit-button {
  color: white;
  /* background-color: #00addb; */
  background-color: #000015;
  color: #a2a9c3;
}
.form-submit-button:hover {
  color: white;
  background-color: #3abdff;
}
/* #successMessage {
  color: #3abdff;
} */

@media only screen and (max-width: 600px) {
  .contact-form {
    padding: 30px;
    margin: auto;
    width: 280px;
  }
}
