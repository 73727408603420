.home-section {
  height: 100vh;
  width: 100%;
  background: radial-gradient(100% 225% at 100% 0%, #ff0000 0%, #000000 100%),
    linear-gradient(236deg, #00c2ff 0%, #000000 100%),
    linear-gradient(
      135deg,
      #cdffeb 0%,
      #cdffeb 36%,
      #009f9d 36%,
      #009f9d 60%,
      #07456f 60%,
      #07456f 67%,
      #0f0a3c 67%,
      #0f0a3c 100%
    );
  background-blend-mode: overlay, hard-light, normal;
  background-size: cover;
}
.home-container-section {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
/* Home Left Section */
.home-left-section {
  width: 50%;
}
.home-left-section {
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
}

.developer-name {
  padding-bottom: 10px;
  color: white;
  letter-spacing: 2px;
  font-size: calc(20px + 1vw);
  font-weight: 500;
}
.developer-title {
  margin-bottom: 15px;
  font-size: 20px;
  letter-spacing: 1px;
  color: #3abdff;
  font-weight: 500;
}

/* Social Links */
.social-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.social-icons {
  padding: 8px;
  font-size: 20px;
  height: 35px;
  width: 35px;
  color: white;
}
.linkedin-link {
  padding: 2px;
  background-color: #0d79b2;
  margin-right: 20px;
  border: 2px solid #00addb;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}
.linkedin-link:hover {
  transform: scale(1.2);
}
.github-link {
  padding: 2px;
  background-color: black;
  margin-right: 20px;
  border-radius: 50%;
  border: 2px solid #00addb;
  transition: all 0.4s ease-in-out;
}
.github-link:hover {
  transform: scale(1.2);
}
.behance-link {
  padding: 2px;
  background-color: #2773f8;
  margin-right: 20px;
  border-radius: 50%;
  border: 2px solid #00addb;
  transition: all 0.4s ease-in-out;
}
.behance-link:hover {
  transform: scale(1.2);
}
.mail-link {
  padding: 2px;
  background-color: #333333;
  border-radius: 50%;
  border: 2px solid #00addb;
  transition: all 0.4s ease-in-out;
}
.mail-link:hover {
  transform: scale(1.2);
}

/* Buttons */
.btn-contact {
  margin-top: 10px;
  height: 35px;
  width: 120px;
  font-size: 18px;
  color: white;
  background-color: #00addb;
  border: none;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-contact:hover {
  color: white;
  background-color: #42bdfe;
}

/* Home Right Section */
.home-right-section {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.home-right-section img {
  width: 80%;
}

@media only screen and (max-width: 700px) {
  .home-left-section {
    width: 100%;
  }
  .developer-name {
    font-size: 22px;
    letter-spacing: 0px;
  }
  .developer-title {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .social-icons {
    padding: 6px;
    font-size: 5px;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .linkedin-link,
  .github-link,
  .behance-link {
    margin-right: 10px;
    padding: 0px;
  }
  .mail-link {
    padding: 0px;
  }
  .btn-contact {
    margin-top: 5px;
    height: 30px;
    width: 100px;
    font-size: 14px;
    color: white;
    background-color: #00addb;
    border: none;
    transition: all 0.4s ease-in-out;
  }
}

@media only screen and (max-width: 700px) {
  .home-container-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .home-left-section {
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
  }
  .home-right-section {
    display: none;
  }
  .home-sections-buttons {
    margin: 0px;
    display: flex;
    justify-content: center;
  }
}
