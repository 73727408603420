#projects {
  padding: 0px 20px 50px;
  background: #000015;
  min-height: 100vh;
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projects-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
