.project-card {
  padding: 20px;
  margin: 15px;
  width: 400px;
  height: 540px;
  background: #1d293a;
  border: 1px solid #23324641;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.project-card-img-section {
  background-position: top;
  background-size: cover;
  transition: linear 2s;
  border: 1px solid #aaaaaa50;
}
.project-card-img-section:hover {
  background-position: bottom;
}
.project-card img {
  height: 180px;
  width: 350px;
  border: 1px solid #80808027;
}
.project-title {
  color: #42bdfe;
}
.project-description {
  height: 120px;
  color: #a2a9c3;
}
.project-technology {
  min-height: 80px;
  color: #a2a9c3;
}
.project-technology strong {
  color: #b4bbd3;
}
.technology-section {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.technology-section p {
  margin: 0px 5px 8px 0px;
  padding: 0px 5px;
  color: #ffffff77;
  border: 1px solid #31373f;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

/* Project Button */
.project-button {
  margin: 0px 5px;
  height: 38px;
  width: 150px;
  color: #b4bbd3;
  background-color: #000015;
  border: none;
  transition: all 0.4s ease-in-out;
}
.project-button:hover {
  color: white;
  background-color: #42bdfe;
}
.project-button span {
  color: #b4bbd3;
}
.project-button:hover span {
  color: white;
}

#link-disabled {
  display: none;
}
@media only screen and (min-width: 650px) {
  .project-button span::before {
    content: "Source ";
  }
}
@media only screen and (max-width: 600px) {
  .project-card {
    width: 280px;
    min-height: 640px;
  }
  .project-description {
    margin-bottom: 20px;
    height: fit-content;
  }
  .project-technology {
    margin-bottom: 20px;
    height: fit-content;
  }
}
