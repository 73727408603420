.blog-page-body {
  height: 100%;
  padding: 120px 20px 30px 20px;
  background: #000015;
}
.blog-content-body {
  background: #1d293a;
  padding: 100px;
}
.blog-title {
  margin-bottom: 20px;
}
.blog-title h1 {
  color: #42c3fe;
  text-align: center;
}
.blog-content {
  padding: 0px 50px;
}
.code-block {
  background-color: #a2a9c3;
  color: black;
  display: block;
  padding: 20px;
}
.blog-content-body h5,
h6,
p,
b,
strong,
li {
  color: #a2a9c3;
}

@media screen and (max-width: 600px) {
  .blog-content-body {
    padding: 30px;
  }
  .blog-title {
    margin-bottom: 0px;
  }
  .blog-content {
    padding: 0px;
  }
}
