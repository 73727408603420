#about {
  padding: 0px 20px 50px;
  min-height: 100vh;
  background: #000015;
  /* background: #1d293a; */
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-me-section-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.about-me-section-image-container {
  margin-bottom: 50px;
}
.about-me-section-image-section img {
  width: 300px;
  /* height: 400px; */
  border-radius: 10px;
  box-shadow: 0 16px 28px 0 rgba(8, 56, 103, 0);
  /* filter: grayscale(30%); */
  transition: 1.5s;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .about-me-section-image-section img {
    width: 300px;
    /* height: 250px; */
  }
}
.about-me-section-image-section img:hover {
  filter: grayscale(0%);
}
.about-me-description p {
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Roboto", sans-serif !important;
  color: #a2a9c3;
  letter-spacing: 1.2px;
  font-weight: 300;
  font-size: 16px;
}

.experience-section-title-row {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.experience-section p {
  margin: 0;
}
.experience-section strong {
  color: #c5c7ce;
  font-size: 18px;
}
.experience-details-link {
  padding: 2px 10px;
  color: #00addb;
  font-size: 12px;
  text-decoration: none;
  border: 1px solid #00addb;
  transition: all 0.4s ease-in-out;
}
.experience-details-link:hover {
  color: #42bdfe;
}
.experience-row {
  padding: 5px 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #233246;
}

.academic-row {
  margin-top: 8px;
}
p.program-name {
  font-size: 12px;
}
p.university-name {
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .experience-row p {
    font-size: 12px;
  }
  .experience-details-link {
    display: none;
  }
}
