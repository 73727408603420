.skills-card-section .skill-card {
  padding: 10px;
  margin: 10px;
  width: 80px;
  height: 80px;
  font-size: 11px;
  text-align: center;
  background: #1d293a;
  border: 1px solid #23324641;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.skill-card div {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skill-card img {
  width: 70%;
}
