body {
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.section-title {
  padding: 50px 0px;
  text-align: center;
  color: #42bdfe;
}

/* Scrollbar Style */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
  background-color: #000015;
  border: 1px solid #1d293a;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #1d293a;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3abdff;
}
