#skills {
  padding: 0px 0px 50px;
  min-height: 100vh;
  background: #000015;
  /* background: #1d293a; */
  border-top: 1px solid #233246;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skills-section {
  display: flex;
  justify-content: center;
  align-items: top;
  flex-wrap: wrap;
}
.frontend-skills,
.backend-skills,
.other-skills {
  margin: 10px 15px;
  padding: 20px;
  max-width: 400px;
  min-height: 500px;
  text-align: center;
  background: #1d293a;
  border: 1px solid #23324641;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.skills-card-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
  .frontend-skills,
  .backend-skills,
  .other-skills {
    width: 280;
  }
}
